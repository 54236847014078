@import '../color.scss';

.sku-card {
  border: 1px solid transparent;
  display: block;

  /* Color */

  &.white {
    background: white;
    border-color: rgba(0, 0, 0, 0.1);
  }

  &.base {
    background: $base;
    border-color: mix(black, $base, 10%);
  }

  &.darkgray {
    background: $darkgray;
    border-color: mix(black, $darkgray, 10%);
  }

  &.gray {
    background: rgba(235, 238, 243, 0.27);
  }

  &.selected {
    border-color: $primary;
    box-shadow: $shadow3, 0 0 0 4px rgba($primary, 0.25);
  }

  /* Shadow */

  &.shadow1 {
    box-shadow: $shadow1;
  }

  &.shadow2 {
    box-shadow: $shadow2;
  }

  &.shadow3 {
    box-shadow: $shadow3;
  }

  &.shadow4 {
    box-shadow: $shadow4;
  }

  &.shadow5 {
    box-shadow: $shadow5;
  }
}
