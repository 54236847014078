@import '../color.scss';

.Badge {
  text-transform: uppercase;
  letter-spacing: 0.08em;
  background: rgba(68, 75, 85, 0.5);
  border-radius: 100px;
  color: white;
  font-weight: 500;
  text-align: center;
  display: inline-block;

  &--content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  /* Size */

  &.small {
    padding: 0 6px;
    font-size: 8px;
    height: 16px;
  }

  &.medium {
    padding: 0 8px;
    font-size: 10px;
    height: 20px;
  }

  &.large {
    padding: 0 10px;
    font-size: 12px;
    height: 24px;
  }

  /* Color */

  &.primary {
    background: $primary;
  }

  &.warning {
    background: $warning;
  }

  &.danger {
    background: $danger;
  }

  &.success {
    background: $success;
  }

  &.info {
    background: $info;
  }

  &.aqua {
    background: $aqua;
  }

  &.indigo {
    background: $indigo;
  }

  &.gray {
    background: $gray;
    @apply text-indigo-800 font-semibold;
  }

  &.outline {
    background: transparent;

    &.primary {
      color: $primary;
    }

    &.warning {
      color: $warning;
    }

    &.danger {
      color: $danger;
    }

    &.success {
      color: $success;
    }

    &.info {
      color: $info;
    }

    &.aqua {
      background: $aqua;
    }

    &.indigo {
      background: $indigo;
    }

    &.gray {
      color: $gray;
    }
  }
}
