@import '../color.scss';

.sku-tooltip {
  position: relative;
  display: inline-block;
  transition: all 0.4s $easing;
  // cursor: help;

  &.underlined {
    box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.15) inset;

    &:hover {
      box-shadow: 0 -2px 0 $primary inset;
    }
  }

  &.top .sku-tooltip-show-enter .sku-tooltip--wrapper,
  &.top .sku-tooltip-show-leave .sku-tooltip--wrapper {
    transform-origin: bottom center;
  }

  &.right .sku-tooltip-show-enter .sku-tooltip--wrapper,
  &.right .sku-tooltip-show-leave .sku-tooltip--wrapper {
    transform-origin: center left;
  }

  &.left .sku-tooltip-show-enter .sku-tooltip--wrapper,
  &.left .sku-tooltip-show-leave .sku-tooltip--wrapper {
    transform-origin: center right;
  }

  &.bottom .sku-tooltip-show-enter .sku-tooltip--wrapper,
  &.bottom .sku-tooltip-show-leave .sku-tooltip--wrapper {
    transform-origin: top center;
  }
}

.sku-tooltip--content {
  border-radius: 4px;
  position: absolute;
  padding: 8px;
  font-size: 12px;
  color: white;
  background: black;
  text-align: center;
  white-space: nowrap;
  z-index: 80;
  transition: all 0.4s $easing;

  /* Positioning */
  &.topNoTail {
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-30%);

    &:after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-30%);
      width: 0;
      height: 0;
    }
  }

  &.rightNoTail {
    left: calc(100% + 8px);
    top: 50%;
    transform: translateY(-50%);

    &:after {
      content: '';
      position: absolute;
      left: -8px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
    }
  }

  &.top {
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);

    &:after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid black;
    }
  }

  &.bottom {
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);

    &:after {
      content: '';
      position: absolute;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid black;
    }
  }

  &.left {
    right: calc(100% + 8px);
    top: 50%;
    transform: translateY(-50%);

    &:after {
      content: '';
      position: absolute;
      right: -8px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 8px solid black;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }
  }

  &.right {
    left: calc(100% + 8px);
    top: 50%;
    transform: translateY(-50%);

    &:after {
      content: '';
      position: absolute;
      left: -8px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-right: 8px solid black;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }
  }

  /* Size */

  &.small {
    padding: 4px 6px;
    font-size: 7px;
  }

  &.medium {
    padding: 6px 8px;
    font-size: 9px;
  }

  &.large {
    padding: 8px 12px;
    font-size: 12px;
  }

  /* Color */

  &.indigo {
    background: $indigo;

    &.top {
      &:after {
        border-top: 8px solid $indigo;
      }
    }

    &.bottom {
      &:after {
        border-bottom: 8px solid $indigo;
      }
    }

    &.left {
      &:after {
        border-left: 8px solid $indigo;
      }
    }
  }
  &.primary {
    background: $primary;
  }

  &.warning {
    background: $warning;
  }

  &.danger {
    background: $danger;
  }

  &.success {
    background: $success;
  }

  &.info {
    background: $info;
  }

  &.multiline {
    white-space: pre;
  }
}

.sku-tooltip--content > * {
  padding: 10px 14px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: $darkgray;
  transition: all 0.4s $easing;
  display: block;
  text-align: left;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    background: $darkgray;
    color: white;
  }

  &:first-child {
    border-top: 0;
  }
}

.sku-tooltip--show-enter {
  opacity: 0.01;
  transform: scale(0.8);
  transform-origin: bottom center;
  transition: transform 0.2s $easing, opacity 0.2s $easing;
}

.sku-tooltip--show-enter.sku-tooltip--show-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: transform 0.2s $easing, opacity 0.2s $easing;
}

.sku-tooltip--show-leave {
  opacity: 1;
  transform: scale(1);
  transition: transform 0.2s $easing, opacity 0.2s $easing;
}

.sku-tooltip--show-leave.sku-tooltip--show-leave-active {
  opacity: 0.01;
  transform: scale(0.8);
  transform-origin: bottom center;
  transition: transform 0.2s $easing, opacity 0.2s $easing;
}
