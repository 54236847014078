@import 'Styles/color.scss';

.SyncNav {
  background: $primary;
  border-bottom: 1px solid mix(black, $primary, 10%);

  &--logo {
    height: 36px;
    width: 36px;
    padding-top: 8px;
    position: absolute;
    left: 50%;
    margin-left: -18px;
  }
}

.SyncInfoModal {
  &--label {
    letter-spacing: 0.08em;
    color: #999;
    font-weight: normal;
  }

  &--item {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15) inset;
    margin-top: -1px;
    transition: all 0.2s $easing;

    &:first-child {
      box-shadow: none;
    }
  }
}
