.Navigation {
  // height: 88px;

  &--container {
    height: 56px;
  }

  &--link {
    color: #85909a;
    font-size: 15px;
    font-weight: 600;

    &.active {
      color: #fff;
    }
  }

  &--logo {
    padding: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -22px;
    margin-left: -22px;
  }

  &--profile {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: #5d6b79;
    border: none;
    display: inline-flex;
    font-size: 18px;
    text-transform: uppercase;
  }
}
