@import 'Styles/color.scss';

.EngageReimbursementDropdown {
  position: relative;

  .sku-button {
    @apply bg-indigo-400 border-none ring-transparent text-white;
  }

  .Button {
    border: none;
    box-shadow: none;

    &.expanded {
      opacity: 0.6;
    }

    & > *:not(div) {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      color: #666;

      &:hover,
      &:active {
        background-color: rgba($primary, 0.15);
        cursor: pointer;
      }
    }
  }

  &--content {
    @apply border-t-4 border-green-400 shadow-md;
    border-radius: 4px;
    position: absolute;
    right: 0;
    top: 48px;
    background: white;
    z-index: 10;

    .Card {
      border: 0 !important;
    }
  }

  &--mobile {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: #5d6b79;
    border: none;
    display: inline-flex;
    color: white;
  }

  &--alertMsg {
    border-radius: 4px;
    box-shadow: $shadow4;
    position: absolute;
    background: white;
    z-index: 10;
    top: 55px;
    right: 118px;

    @media screen and (max-width: 576px) {
      right: 0;
    }

    &:before {
      content: '';
      position: absolute;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 20px solid white;
      top: -12px;
      right: 116px;
    }

    .Modal {
      &--inner {
        width: 300px !important;
      }
    }
  }

  h1 {
    font-weight: 400;
    font-size: 45px;
  }
}
