.sku-textinput {
  @apply rounded border outline-none transition-all ease-in-out;

  &:focus,
  &:hover:not(:disabled) {
    @apply bg-white ring-4;
  }

  &:disabled {
    @apply opacity-30;
  }

  @mixin theme($color) {
    &.#{$color} {
      @apply bg-#{$color}-50 bg-opacity-40 border-#{$color}-300 ring-#{$color}-50;

      &:focus,
      &:hover:not(:disabled) {
        @apply bg-white border-#{$color}-300;
      }

      &.hasValue {
        @apply border-transparent;
      }
    }
  }

  @include theme('green');
  @include theme('berry');

  /* gray's border color is lighter*/
  &.gray {
    @apply bg-gray-100 bg-opacity-40 border-gray-200 ring-gray-50 text-black;

    &:focus,
    &:hover:not(:disabled) {
      @apply bg-white border-gray-100;
    }

    &:disabled {
      @apply border-gray-50 bg-gray-50 opacity-100;
    }
  }
}
