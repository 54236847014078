@import '../color.scss';

.sku-loader {
  font-size: inherit;
  text-align: center;

  /* Color */

  &.primary {
    .sku-loader--indicator {
      stroke: $primary;
    }
  }

  &.warning {
    .sku-loader--indicator {
      stroke: $warning;
    }
  }

  &.danger {
    .sku-loader--indicator {
      stroke: $danger;
    }
  }

  &.success {
    .sku-loader--indicator {
      stroke: $success;
    }
  }

  &.info {
    .sku-loader--indicator {
      stroke: $info;
    }
  }

  &.white {
    .sku-loader--indicator {
      stroke: white;
    }

    .sku-loader--label {
      color: white;
    }
  }

  &--label {
    color: $darkgray;
    margin-top: 12px;
    text-align: center;
    font-weight: 500;
  }
}
