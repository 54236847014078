@import '../color.scss';

//DATEPICKER VARIABLES
$datepicker__background-color: $primary !default;
$datepicker__border-color: #ddd !default;
$datepicker__highlighted-color: $primary !default;
$datepicker__muted-color: rgba(black, 0.2);
$datepicker__selected-color: $primary !default;
$datepicker__text-color: #333 !default;
$datepicker__header-color: #fff !default;
$datepicker__navigation-disabled-color: #ccc !default;

$datepicker__border-radius: 4px !default;
$datepicker__day-border-radius: 9999px !default;
$datepicker__day-margin: 4px !default;
$datepicker__font-size: 12px !default;
$datepicker__font-family: inherit !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;

//DATEPICKER MIXINS
%triangle-arrow {
  margin-left: -$datepicker__triangle-size;
  position: absolute;

  &,
  &::before {
    box-sizing: content-box;
    position: absolute;
    border: $datepicker__triangle-size solid transparent;

    height: 0;
    width: 1px;
  }

  &::before {
    content: '';
    z-index: -1;
    border-width: $datepicker__triangle-size;

    left: -$datepicker__triangle-size;
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-up {
  @extend %triangle-arrow;

  top: 0;
  margin-top: -$datepicker__triangle-size;

  &,
  &::before {
    border-top: none;
    border-bottom-color: $datepicker__background-color;
  }

  &::before {
    top: -1px;
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-down {
  @extend %triangle-arrow;

  bottom: 0;
  margin-bottom: -$datepicker__triangle-size;

  &,
  &::before {
    border-bottom: none;
    border-top-color: #fff;
  }

  &::before {
    bottom: -1px;
    border-top-color: $datepicker__border-color;
  }
}

//DATEPICKER SCSS
.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker {
  font-family: $datepicker__font-family;
  font-size: $datepicker__font-size;
  background-color: #fff;
  color: $datepicker__text-color;
  border: 1px solid $datepicker__border-color;
  border-radius: $datepicker__border-radius;
  display: inline-block;
  position: relative;
  box-shadow: $shadow5;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker {
  animation: datepicker-expand-down 0.4s $easing;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker {
  animation: datepicker-expand-up 0.4s $easing;
}

.react-datepicker--time-only {
  .react-datepicker__triangle {
    left: 35px;
  }

  .react-datepicker__time-container {
    border-left: 0;
  }

  .react-datepicker__time {
    border-radius: 0.3rem;
  }

  .react-datepicker__time-box {
    border-radius: 0.3rem;
  }
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;

  &[data-placement^='bottom'] {
    margin-top: $datepicker__triangle-size + 2px;

    .react-datepicker__triangle {
      @extend %triangle-arrow-up;
    }
  }

  &[data-placement^='top'] {
    margin-bottom: $datepicker__triangle-size + 2px;

    .react-datepicker__triangle {
      @extend %triangle-arrow-down;
    }
  }

  &[data-placement^='right'] {
    margin-left: $datepicker__triangle-size;

    .react-datepicker__triangle {
      left: auto;
      right: 42px;
    }
  }

  &[data-placement^='left'] {
    margin-right: $datepicker__triangle-size;

    .react-datepicker__triangle {
      left: 42px;
      right: auto;
    }
  }
}

.react-datepicker__header {
  text-align: center;
  background-color: $datepicker__background-color;
  border-bottom: 1px solid $datepicker__border-color;
  border-top-left-radius: $datepicker__border-radius;
  border-top-right-radius: $datepicker__border-radius;
  padding-top: 8px;
  position: relative;

  &--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: $datepicker__header-color;
  font-weight: bold;
  font-size: $datepicker__font-size * 1.4;
  padding: 4px 0;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: $datepicker__item-size;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 14px;
  width: 0;
  padding: 0;
  border: $datepicker__navigation-size solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;

  &--previous {
    left: 10px;
    border-right-color: $datepicker__muted-color;

    &:hover {
      border-right-color: darken($datepicker__muted-color, 0.1);
    }

    &--disabled,
    &--disabled:hover {
      border-right-color: $datepicker__navigation-disabled-color;
      cursor: default;
    }
  }

  &--next {
    right: 10px;
    border-left-color: $datepicker__muted-color;
    &--with-time:not(&--with-today-button) {
      right: 80px;
    }

    &:hover {
      border-left-color: darken($datepicker__muted-color, 0.1);
    }

    &--disabled,
    &--disabled:hover {
      border-left-color: $datepicker__navigation-disabled-color;
      cursor: default;
    }
  }

  &--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &-previous {
      top: 4px;
      border-top-color: $datepicker__muted-color;

      &:hover {
        border-top-color: darken($datepicker__muted-color, 0.1);
      }
    }

    &-upcoming {
      top: -4px;
      border-bottom-color: $datepicker__muted-color;

      &:hover {
        border-bottom-color: darken($datepicker__muted-color, 0.1);
      }
    }
  }
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: $datepicker__margin;
  text-align: center;
  .react-datepicker__month-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
  .react-datepicker-time__caption {
    display: inline-block;
  }
  .react-datepicker-time__input-container {
    display: inline-block;
    .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px;
      input {
        width: 85px;
      }
      input[type='time']::-webkit-inner-spin-button,
      input[type='time']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type='time'] {
        -moz-appearance: textfield;
      }
    }
    .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block;
    }
  }
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid $datepicker__border-color;
  width: 70px;

  &--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0;
  }

  .react-datepicker__time {
    position: relative;
    background: white;

    .react-datepicker__time-box {
      width: 70px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
      ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (#{$datepicker__item-size} / 2));
        overflow-y: scroll;
        padding-right: 0px;
        padding-left: 0px;
        width: 100%;
        box-sizing: content-box;

        li.react-datepicker__time-list-item {
          height: 30px;
          padding: 5px 10px;
          &:hover {
            cursor: pointer;
            background-color: $datepicker__background-color;
          }
          &--selected {
            background-color: $datepicker__selected-color;
            color: white;
            font-weight: bold;
            &:hover {
              background-color: $datepicker__selected-color;
            }
          }
          &--disabled {
            color: $datepicker__muted-color;

            &:hover {
              cursor: default;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.react-datepicker__week-number {
  color: $datepicker__muted-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
  &.react-datepicker__week-number--clickable {
    cursor: pointer;
    &:hover {
      border-radius: $datepicker__border-radius;
      background-color: $datepicker__background-color;
    }
  }
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: $datepicker__text-color;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: $datepicker__item-size;
  height: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
}

.react-datepicker__day-name {
  color: $datepicker__header-color;
}

.react-datepicker__month {
  &--selected,
  &--in-range {
    border-radius: $datepicker__border-radius;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__selected-color, 0.05);
    }
  }
  &--disabled {
    color: $datepicker__muted-color;
    pointer-events: none;
    &:hover {
      cursor: default;
      background-color: transparent;
    }
  }
}

.react-datepicker__day {
  cursor: pointer;

  &--today {
    font-weight: bold;
  }

  &--highlighted {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__highlighted-color;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__highlighted-color, 0.05);
    }
  }

  &--disabled {
    cursor: default;
    color: $datepicker__muted-color;

    &:hover {
      background-color: rgba($datepicker__selected-color, 0.02);
    }
  }

  &--selected {
    border-radius: 9999px;
    background-color: rgba($datepicker__selected-color, 0.1);
    color: $datepicker__selected-color;

    &.react-datepicker__day--range-start {
      background-color: rgba($datepicker__selected-color, 0.1) !important;
    }

    &:hover {
      background-color: rgba($datepicker__selected-color, 0.1) !important;
    }
  }

  &--range-start,
  &--selecting-range-start,
  &--range-end,
  &--selecting-range-end {
    background-color: rgba($datepicker__selected-color, 0.1);
    color: $datepicker__selected-color;
    border-radius: 9999px !important;
  }

  &--range-start {
    &:after {
      @apply rounded-l-full;
      width: calc(1.7rem + 4px);
      margin-left: 4px;
    }
  }

  &--range-end {
    &.react-datepicker__day--in-range {
      background-color: rgba($datepicker__selected-color, 0.1);
    }
    &:after {
      @apply rounded-r-full;
      width: calc(1.7rem + 4px);
      margin-right: 4px;
    }
  }

  &--in-range {
    color: $datepicker__selected-color;
    background-color: rgba($datepicker__selected-color, 0.01);

    &:after {
      content: '';
      display: block;
      background-color: transparent !important;
      position: absolute;
      width: calc(1.7rem + 8px);
      height: 1.7rem;
    }
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: #fff;
    color: #000;
  }

  &--keyboard-selected {
    background-color: #fff;
    color: $datepicker__selected-color;
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: #fff;
      color: #000;
    }
  }

  &:hover {
    color: $datepicker__selected-color;
    border-radius: 9999px;
    background-color: rgba($datepicker__selected-color, 0.1) !important;
  }
}

//SKUPOS
.react-datepicker {
  :focus {
    outline: none;
  }
}

@keyframes datepicker-expand-up {
  0% {
    transform: scale(0.95) translateY(12px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

@keyframes datepicker-expand-down {
  0% {
    transform: scale(0.95) translateY(-12px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}
