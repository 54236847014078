@import 'Styles/color.scss';

$sidebar-width: 180px;

.Sidebar {
  &--overlay {
    content: '';
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.25);
    animation: Sidebar--fade-in 0.4s $easing 0s;
  }

  &--spacer {
    width: $sidebar-width;
  }

  &--menu {
    width: $sidebar-width;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    height: 100vh;
    background: white;
    box-sizing: border-box;
    animation: Sidebar--slide-in 0.4s $easing 0s;
  }

  &--header {
    height: 36px;
    position: relative;
    top: 1px;
  }

  &--link {
    color: mix(white, $indigo, 35%);
    font-weight: 500;
    font-size: 13px;
    line-height: 1;

    &.active {
      @apply text-indigo-500 bg-gray-50 border-gray-100 rounded ring-transparent;
    }
  }
}

@keyframes Sidebar--fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes Sidebar--slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
