@import 'Styles/color.scss';

.CloverLogin {
  padding-top: 64px;

  &--container {
    max-width: 320px;
    width: 100%;
  }

  &--logo {
    width: 64px;
    height: 64px;
  }

  &--form {
    padding: 24px;
    border-top: 6px solid $primary;
    border-radius: 4px 4px 0 0;
  }

  &--link {
    font-size: 14px;
    text-decoration: none;
    color: $info;
  }
}
