@import 'Styles/color.scss';

.Footer {
  color: #999;
  font-size: 12px;
  &--link {
    color: #999 !important;
    text-decoration: none;
    font-size: 12px;
  }
}
