@import 'Styles/color.scss';

.CloverNav {
  background: $primary;
  border-bottom: 1px solid mix(black, $primary, 10%);

  &--logo {
    height: 36px;
    width: 36px;
    padding-top: 8px;
    position: absolute;
    left: 50%;
    margin-left: -18px;
  }
}
