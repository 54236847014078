$lightgray: #d8d8d8;

.sku-select {
  font-size: 13px;

  &__control {
    background-color: #fff;
    min-height: 32px;
    border: 0 !important;
    box-shadow: 0 0 0 1px $lightgray;
    @apply transition-all duration-300 ease-in-out;

    &:hover {
      @apply ring-4 ring-green-500 ring-opacity-20 #{!important};

      .sku-select__placeholder,
      .sku-select__single-value,
      .sku-select__indicator {
        @apply text-green-500;
      }
    }

    &--is-focused {
      box-shadow: 0 0 0 1px $lightgray !important;
    }

    &--menu-is-open {
      @apply text-green-500;
      border: 0 !important;
      box-shadow: none !important;
      background: #f6fdfb !important;

      .sku-select__placeholder,
      .sku-select__single-value,
      .sku-select__indicator {
        @apply text-green-500;
      }
    }
  }

  &__value-container {
    padding-left: 16px !important;
  }

  &__indicator-separator {
    width: 0 !important;
  }

  &__placeholder {
    color: $lightgray;
  }

  &__indicator {
    padding: 7px;
  }

  &__menu {
    &::before {
      @apply block absolute bg-green-400 rounded-t w-full h-1;
      content: '';
      top: 0;
      z-index: 1;
    }
    @apply shadow-md #{!important};
  }

  &__option {
    padding: 10px 20px !important;
  }

  &__option--is-focused,
  &__option:active {
    @apply bg-green-50 bg-opacity-40 #{!important};
  }

  &__option--is-selected {
    @apply font-medium text-green-400 bg-green-50 bg-opacity-40 #{!important};
  }
}
