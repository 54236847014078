@import '../color.scss';

.sku-alert {
  background: mix(white, $indigo, 95%);
  border-radius: 4px;
  color: $indigo;
  border: 1px solid mix(white, $indigo, 70%);
  text-align: left;
  box-shadow: $shadow1;
  display: flex;
  width: 100%;

  /* Color */

  &.indigo {
    background: $indigo;
    border-color: mix(black, $indigo, 10%);
    color: white;
  }

  &.primary {
    background: $primary;
    border-color: mix(black, $primary, 10%);
    color: white;
  }

  &.warning {
    background: mix(white, $warning, 90%);
    border-color: mix(white, $warning, 40%);
    color: mix(black, $warning, 25%);
  }

  &.danger {
    background: mix(white, $danger, 90%);
    border-color: mix(white, $danger, 50%);
    color: mix(black, $danger, 25%);
  }

  &.berry {
    background: mix(white, $berry, 90%);
    border-color: mix(white, $berry, 50%);
    color: mix(black, $berry, 25%);
  }

  &.success {
    background: mix(white, $success, 90%);
    border-color: mix(white, $success, 50%);
    color: mix(black, $success, 25%);
  }

  &.info {
    background: mix(white, $info, 90%);
    border-color: mix(white, $info, 50%);
    color: mix(black, $info, 25%);
  }

  &--icon {
    flex: 0 0 auto;
    padding-left: 12px;
    font-size: 22px;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
  }

  &--content {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &--close {
    flex: 0 0 40px;
    height: 40px;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.25);
    transition: all 0.4s $easing;
    transform: scale(1);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
  }
}

/* Size */
.sku-alert--content {
  align-self: center;

  &.small {
    padding: 6px 10px;
    font-size: 12px;
  }

  &.medium {
    padding: 9px 12px;
    font-size: 14px;
  }

  &.large {
    padding: 12px 16px;
    font-size: 16px;
  }
}

.sku-alert--close:hover {
  color: inherit;
  transform: scale(1.2);
  cursor: pointer;
}

.sku-alert--close:active {
  color: inherit;
  transform: scale(1);
  cursor: pointer;
}
