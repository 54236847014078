@import '../color.scss';

.sku-dropdown {
  position: relative;
  &.isOpen {
    &:before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  &--menu {
    @apply bg-white rounded absolute z-10 overflow-auto max-h-[60vh] shadow-md text-sm font-normal border-t-4 border-green-400 pt-2 pb-3;

    & > *:not(.sku-dropdown--label) {
      @apply block no-underline cursor-pointer text-current text-gray-700 px-4 py-2;

      &:hover,
      &:active {
        @apply bg-green-50 font-medium;
      }
    }
  }

  &--label {
    @apply block no-underline text-gray-400 px-4 py-2 text-xs;
  }
}
