#content {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

#sync-content {
  min-width: 400px;
  background: white;
  height: 100vh;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#clover-content {
  min-width: 400px;
  height: 100vh;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* for react-router-transition */
.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
  min-height: calc(100vh - 96px);
  display: flex;
  flex-direction: column;
}
